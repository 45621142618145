<template>
    <ValidationObserver ref="form">
        <modal ref="modalCrearPremio" titulo="Crear Premio" no-aceptar adicional="Guardar" tamano="modal-lg" icon="box" :cargado-modal="cargando" @adicional="crearPremio">
            <div class="row mx-0 px-5">
                <slim-cropper ref="fotoGamingProducto" :options="slimOptions" class="border cr-pointer styles-cropper" style="" />
                <div class="col-auto" />
                <div class="col">
                    <p class="text-general f-12 pl-3">
                        Nombre
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:60" name="nombre">
                        <el-input v-model="nombre" class="w-100" size="small" placeholder="Nombre del premio" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                    <p class="text-general f-12 pl-3 mt-3">
                        Categoría
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="categoría">
                        <el-select v-model="categoria" class="w-100" size="small" placeholder="Seleccione una categoría">
                            <el-option
                            v-for="(cat,index) in categorias"
                            :key="index"
                            :label="cat.nombre"
                            :value="cat.id"
                            />    
                        </el-select>
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                    <p class="text-general f-12 pl-3 mt-3">
                        Descripción
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:60" name="descripción">
                        <el-input v-model="descripcion" type="textarea" :rows="4" class="w-100" size="small" placeholder="Descripción" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
            </div>
        </modal>
    </ValidationObserver>
</template>

<script>

import GamingProductos from '~/services/gaming/gamingProductos';
export default {
    data(){
        return {
            cargando: false,
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            nombre: '',
            categoria: null,
            descripcion: '',
            categorias: [],
            idCedis: null
        }
    },
    mounted(){
        this.getListaCategorias()
    },
    methods: {
        toggle(idCedis){
            this.limpiar()
            this.idCedis = idCedis
            this.$refs.modalCrearPremio.toggle();
        },
        async getListaCategorias(){
            try {
                const { data } = await GamingProductos.getCategoriasGamingProducto()
                this.categorias = data.data 
            } catch (e){
                this.error_catch(e)
            }
        },
        async crearPremio(){
            try {
                let validate = await this.$refs.form.validate()
                
                if(!validate) return this.notificacion("","Campos incompletos","warning")

                this.cargando = true
                let params = {
                    nombre: this.nombre,
                    id_categoria: this.categoria,
                    descripcion: this.descripcion,
                    foto: this.$refs.fotoGamingProducto.instanciaCrop.dataBase64.output.image,
                    id_cedis: this.idCedis
                }
                const { data } = await GamingProductos.crearGamingProducto(params)

                this.limpiar()
                this.$emit('update')
                this.notificacion("","Premio creado correctamente","success")
                this.$refs.modalCrearPremio.toggle();
            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargando = false
            }
        },
        limpiar(){
            this.nombre = ''
            this.categoria = null
            this.descripcion = ''
            this.idCedis = null
            this.$refs.fotoGamingProducto.instanciaCrop.remove()
        }
    }
}
</script>
<style lang="scss" scoped>
.styles-cropper{
    width: 191px;
    height: 191px;
    border: 1px solid #DBDBDB;
    background-color: #F5F5F5;
    border-radius: 12px;
}
</style>